import React, { useState } from 'react';
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  MenuItem,
  Checkbox,
  ListItemText,
  TablePagination,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SearchIcon from '@mui/icons-material/Search';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

import FilterAltIcon from "@mui/icons-material/FilterAlt";

import Design1Image from './Dimage/design1.jpg';
import Design2Image from './Dimage/design2.jpg';

const Order = () => {
  const handleEdit = (id) => {
    // Handle edit logic for the specified id
  };

  const handleDelete = (id) => {
    // Handle delete logic for the specified id
  };

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("1");
  const [design, setDesign] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const options = [
    { value: 'design1', label: 'Design 1', image: Design1Image },
    { value: 'design2', label: 'Design 2', image: Design2Image },
    // Add more options with their respective labels and images
  ];

  const rows = [
    { id: 1, date: '2023-06-15', purity: '10', weight: '10', customerName: 'Customer 1', designName: 'Design 1', designImage: Design1Image },
    // Add more rows with the corresponding data
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const inactiveData = []; // Replace [] with your actual data or initialization logic


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOptionChange = (event) => {
    setSelectedOptions(event.target.value);
  };

  return (
    <>
      <Box height={60} />
      <Typography variant="h6" component="h6" noWrap>
        Order Details
      </Typography>
      <Container maxWidth="xxl">
        <Box mt={2}>
          <Card>
            <CardContent>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      placeholder="Search"
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} textAlign="right">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<GroupAddIcon />}
                      onClick={handleClickOpen}
                    >
                      Order
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              <Box mt={2}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="Orders Tabs">
                    <Tab label="Active" value="1" sx={{ flex: 1 }} />
                  <Tab label="Inactive" value="2" sx={{ flex: 1.2 }} />
                    </TabList>
                  </Box>

                  <Box mt={2}>
                    <TabPanel value="1">
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow sx={{ backgroundColor: "grey.200" }}>
                              <TableCell>S.No</TableCell>
                              <TableCell>
                              <Box display="flex" alignItems="center">
                              Date
                              <IconButton size="small">
                                <FilterAltIcon />
                              </IconButton>
                            </Box>
                            </TableCell>
                              <TableCell>Purity</TableCell>
                              <TableCell>Weight</TableCell>
                              <TableCell>
                              <Box display="flex" alignItems="center">
                              Customer Name
                              <IconButton size="small">
                                <FilterAltIcon />
                              </IconButton>
                            </Box>
                            </TableCell>
                            <TableCell>
                            <Box display="flex" alignItems="center">
                              Design Name
                              <IconButton size="small">
                                <FilterAltIcon />
                              </IconButton>
                            </Box>
                            </TableCell>
                              <TableCell>Design Image</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                              <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.purity}</TableCell>
                                <TableCell>{row.weight}</TableCell>
                                <TableCell>{row.customerName}</TableCell>
                                <TableCell>{row.designName}</TableCell>
                                <TableCell>
                                  <img src={row.designImage} alt={row.designName} style={{ width: '50px' }} />
                                </TableCell>
                                <TableCell>
                                  <IconButton aria-label="edit" onClick={() => handleEdit(row.id)}>
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton aria-label="delete" onClick={() => handleDelete(row.id)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={rows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableContainer>
                    </TabPanel>
                  </Box>

                  <Box mt={2}>
                    <TabPanel value="2">
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow sx={{ backgroundColor: "grey.200" }}>
                              <TableCell>S.No</TableCell>
                              <TableCell>
                              <Box display="flex" alignItems="center">
                              Date
                              <IconButton size="small">
                                <FilterAltIcon />
                              </IconButton>
                            </Box>
                            </TableCell>
                              <TableCell>Purity</TableCell>
                              <TableCell>Weight</TableCell>
                              <TableCell>
                              <Box display="flex" alignItems="center">
                              Customer Name
                              <IconButton size="small">
                                <FilterAltIcon />
                              </IconButton>
                            </Box>
                            </TableCell>
                              <TableCell>
                            <Box display="flex" alignItems="center">
                              Design Name
                              <IconButton size="small">
                                <FilterAltIcon />
                              </IconButton>
                            </Box>
                            </TableCell>
                              <TableCell>Design Image</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {inactiveData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                              <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.purity}</TableCell>
                                <TableCell>{row.weight}</TableCell>
                                <TableCell>{row.customerName}</TableCell>
                                <TableCell>{row.designName}</TableCell>
                                <TableCell>
                                  <img src={row.designImage} alt={row.designName} style={{ width: '50px' }} />
                                </TableCell>
                                <TableCell>
                                  <IconButton aria-label="edit" onClick={() => handleEdit(row.id)}>
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton aria-label="delete" onClick={() => handleDelete(row.id)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={inactiveData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableContainer>
                    </TabPanel>
                  </Box>
                </TabContext>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>

      {/* Dialog for adding a worker */}
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Order</DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="purity"
              label="Purity"
              type="text"
              variant="standard"
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="weight"
              label="Weight"
              type="text"
              variant="standard"
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="customerName"
              label="Customer Name"
              select
              variant="standard"
              fullWidth
              sx={{ mb: 2 }}
            >
              <MenuItem value="customer1">Customer 1</MenuItem>
              <MenuItem value="customer2">Customer 2</MenuItem>
              <MenuItem value="customer3">Customer 3</MenuItem>
            </TextField>

            <TextField
              autoFocus
              margin="dense"
              id="designName"
              label="Design Name"
              type="text"
              variant="standard"
              fullWidth
              sx={{ mb: 2 }}
            />

            <TextField
              autoFocus
              margin="dense"
              id="designCode"
              label="Design Code"
              type="text"
              variant="standard"
              fullWidth
              sx={{ mb: 2 }}
            />

            <TextField
              select
              label="Designs"
              value={selectedOptions}
              onChange={handleOptionChange}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map((value) => (
                      <Box key={value} sx={{ p: 0.5 }}>
                        <Checkbox checked={selectedOptions.indexOf(value) > -1} />
                        <img
                          src={options.find((option) => option.value === value)?.image}
                          alt={options.find((option) => option.value === value)?.label}
                          style={{ width: 50, height: 50 }}
                        />
                        <Typography variant="body2" align="center">
                          {options.find((option) => option.value === value)?.label}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ),
              }}
              fullWidth
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={selectedOptions.indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleClose}>Submit</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Order;
