import React from "react";
import Box from "@mui/material/Box";

export default function WorkOrderSetting() {
  return (
    <>
      <Box height={100} />
      <div>WorkOrderSetting</div>
      <h1>Hello</h1>
    </>
  );
}
