import React from 'react'
import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert

} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {
  Delete as DeleteIcon,
} from "@mui/icons-material";

import axios from "axios";
import { GiMeltingIceCube } from 'react-icons/gi';
import "./melt.css";


const Melting = () => {
  const [open, setOpen] = useState(false);
  const [firstRow, setFirstRow] = useState({ katcha: '', purity: '', kgWeight: '', neededWeight: '' });
  const [rows, setRows] = useState([]);
  const [totalPurityCount, setTotalPurityCount] = useState(0);
  const [topPurity, setTopPurity] = useState('');
  const [dynamicWeight, setDynamicWeight] = useState(0);
   
  const [columnTotals, setColumnTotals] = useState({
    katcha: 0,
    purity: 0,
    kgWeight: 0,
    neededWeight: 0,
  });
    // State for success and error messages
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
  
  const handleAddRow = () => {
    setRows([...rows, { ...firstRow }]);
    setFirstRow({ katcha: '', purity: '', kgWeight: '', neededWeight: '' });
    setTotalPurityCount(totalPurityCount + 1); // Increment purity count
  };
  

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
    setTotalPurityCount(totalPurityCount - 1); // Decrement purity count
  };
  

  const handleInputChange = (index, field, value) => {
    const updatedRows = rows.map((row, i) => {
      if (i === index) {
        const updatedRow = { ...row, [field]: value };
        updateColumnTotals(updatedRow, field);
        return updatedRow;
      }
      return row;
    });
    setRows(updatedRows);
  };
  
  const updateColumnTotals = (row, field) => {
    const newColumnTotals = { ...columnTotals };
    newColumnTotals[field] += parseFloat(row[field]) || 0;
    setColumnTotals(newColumnTotals);
  };
  

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
 
  const calculateColumnTotals = () => {
    const newColumnTotals = {
      katcha: 0,
      purity: 0,
      kgWeight: 0,
      neededWeight: 0,
    };
  
    rows.forEach((row) => {
      newColumnTotals.katcha += parseFloat(row.katcha) || 0;
      newColumnTotals.purity += parseFloat(row.purity) || 0;
      newColumnTotals.kgWeight += parseFloat(row.kgWeight) || 0;
      newColumnTotals.neededWeight += parseFloat(row.neededWeight) || 0;
    });
  
    // Calculate average purity as a percentage
    newColumnTotals.purity = (newColumnTotals.purity / totalPurityCount);
  
    // Calculate kgWeight based on katcha and purity percentage
    newColumnTotals.kgWeight = (newColumnTotals.katcha * newColumnTotals.purity) / 100;
  
     // Calculate needed weight using the formula: needed weight = katcha - dynamic weight
     newColumnTotals.neededWeight = (newColumnTotals.katcha - dynamicWeight);

    return newColumnTotals;
  };
  
  // Calculate dynamic weight using the formula provided
  const calculateDynamicWeight = () => {
    return (calculateColumnTotals().kgWeight / topPurity) * 100;
  };
  
  useEffect(() => {
    // Calculate dynamic weight and update the state
    setDynamicWeight(calculateDynamicWeight());
    
    // Calculate new column totals
    const newColumnTotals = calculateColumnTotals();
    setColumnTotals(newColumnTotals);
  }, [rows, topPurity]);
  
const Needed = (columnTotals.katcha - dynamicWeight)

  const saveDataToDatabase = async () => {
    try {
      const response = await axios.post("https://apipsr.algojaxon.com/melt", [{
        TPurity: topPurity,
        Dweight: dynamicWeight,
        Katcha: columnTotals.katcha,
        KPurity: columnTotals.purity,
        KPweight: columnTotals.kgWeight,
        Nweight: Needed,
      }]);

      console.log("Data saved successfully:", response.data);
      // Show success message
      setSuccessMessage("Data saved successfully.");
      setShowSuccessMessage(true);
      setShowErrorMessage(false);

  // Reset the input fields and table data
  setDynamicWeight(0)
  setFirstRow({ katcha: "", purity: "", kgWeight: "", neededWeight: "" });
  setRows([]);

   // Reload the page after a short delay (e.g., 2 seconds)
   setTimeout(() => {
    window.location.reload();
  }, 1000); // Adjust the delay as needed


    } catch (error) {
      console.error("Error saving data:", error);
      // Show error message
      setErrorMessage("Error saving data.");
      setShowErrorMessage(true);
      setShowSuccessMessage(false);

      // Handle error or show an error message to the user
    }
  };



  
  
  
  return (
    <>   
 {/* Success Message */}
 <Snackbar
        open={showSuccessMessage}
        autoHideDuration={3000} // Adjust as needed
        onClose={() => setShowSuccessMessage(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowSuccessMessage(false)} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Message */}
      <Snackbar
        open={showErrorMessage}
        autoHideDuration={3000} // Adjust as needed
        onClose={() => setShowErrorMessage(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowErrorMessage(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>


      <Box height={60} />
      <Typography variant="h6" component="h6" noWrap>
        Melting
      </Typography>

      <Container maxWidth="xxl">
        <Box mt={2}>
          <Card>
            <CardContent>
              <Box>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={6} md={6}></Grid>
                  <Grid item xs={12} sm={6} md={6} textAlign="right">
                  <Button
                      variant="contained"
                      startIcon={<GiMeltingIceCube />}
                      sx={{ backgroundColor: "green" }}
                      onClick={handleOpenModal} // Call the function on button click
                    >
                      Melting
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "grey.200" }}>
                      <TableCell>S.No</TableCell>
                      <TableCell>PSC</TableCell>
                      <TableCell>VA (Copper)</TableCell>
                      <TableCell>Total Weight</TableCell>
                    </TableRow>
                  </TableHead>
                  {/* <TableBody>
                    
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>5</TableCell>
                        <TableCell>5.8</TableCell>
                        <TableCell>20kg</TableCell>
                      </TableRow>
                  
                  </TableBody> */}
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
        
      </Container>
      
      <Dialog open={open} onClose={handleCloseModal} maxWidth="xxl" fullScreen>
        <DialogTitle
  id="form-dialog-title"
  style={{
    backgroundColor: "#4d84e2",
    color: "white",
    display: "flex",         // Use flex container
    alignItems: "center",    // Vertically center content
    justifyContent: "space-between", // Add this to push the close button to the right
  
  }}
>
  <div>Melting Table</div>
  <IconButton
    onClick={handleCloseModal}
    color="inherit"
    aria-label="Close"
    style={{marginTop:'-20px'}} // Set the initial color to white
    onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'black'} // Change color to black on hover
    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#4d84e2'} // Change color back to white when not hovering
  >
    <CloseIcon />
  </IconButton>
</DialogTitle>
        <DialogContent>
        <TableContainer component={Paper}>  
        
        <Box display="flex" justifyContent="end" alignItems="center">
  <TextField
    id="top-purity-input"
    label="Top Purity (%)"
    variant="standard"
    value={topPurity}
    onChange={(e) => {
      const newPercentage = e.target.value;
      setTopPurity(newPercentage);
    }}
  />
  <Typography variant="body1">
      Total Weight: {isNaN(dynamicWeight) ? "" : dynamicWeight}
    </Typography>

</Box>





            <Table sx={{ marginTop:"20px"}}>
              <TableHead>              
                <TableRow style={{backgroundColor:"#EEEEEE", color:"white",}}>
                  <TableCell>S.No</TableCell>
                  <TableCell>Katcha</TableCell>
                  <TableCell>Purity</TableCell>
                  <TableCell>KP.Weight</TableCell>
                  <TableCell>Needed Weight</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {firstRow && (
                  <TableRow>
                    <TableCell>1</TableCell>
                    <TableCell>
                      <TextField
                      variant="standard"
                        type="number"
                        value={firstRow.katcha}
                        onChange={(e) => setFirstRow({ ...firstRow, katcha: e.target.value })}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                      variant="standard"
                        type="number"
                        value={firstRow.purity}
                        onChange={(e) => setFirstRow({ ...firstRow, purity: e.target.value })}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                      variant="standard"
                        type="number"
                        value={firstRow.kgWeight}
                        onChange={(e) => setFirstRow({ ...firstRow, kgWeight: e.target.value })}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                      variant="standard"
                        type="text"
                        value={firstRow.neededWeight}
                        onChange={(e) => setFirstRow({ ...firstRow, neededWeight: e.target.value })}
                      />
                    </TableCell>
                    <TableCell>
                      <Button variant="outlined" onClick={handleAddRow}>
                        Add
                      </Button>
                    </TableCell>
                  </TableRow>
                )}

{rows.map((row, index) => (
  <TableRow key={index}>
    <TableCell>{index + 2}</TableCell>
    <TableCell>
      <TextField
        variant="standard"
        type="number"
        value={row.katcha}
        onChange={(e) => handleInputChange(index, 'katcha', e.target.value)}
      />
    </TableCell>
    <TableCell>
      <TextField
        variant="standard"
        type="number"
        value={row.purity}
        onChange={(e) => handleInputChange(index, 'purity', e.target.value)}
      />
    </TableCell>
    <TableCell>
      <TextField
        variant="standard"
        type="number"
        value={((parseFloat(row.katcha) || 0) * (parseFloat(row.purity) || 0)) / 100}
        disabled
      />
    </TableCell>
    <TableCell>
      <TextField
        variant="standard"
        type="number"
        value={(parseFloat(row.katcha) || 0) - dynamicWeight}
        disabled
      />
    </TableCell>
    <TableCell>
      <IconButton onClick={() => handleDeleteRow(index)}>
        <DeleteIcon />
      </IconButton>
    </TableCell>
  </TableRow>
))}

                {/* Row displaying totals */}
                <TableRow>
  <TableCell sx={{ color: "Black", fontSize: "20px" }}>Total</TableCell>
  <TableCell sx={{ color: "Black", fontSize: "20px" }}>
    {isNaN(columnTotals.katcha) ? "0.00" : columnTotals.katcha.toFixed(2)}
  </TableCell>
  <TableCell sx={{ color: "Black", fontSize: "20px" }}>
    {isNaN(columnTotals.purity) ? "0.00" : columnTotals.purity.toFixed(2)}%
  </TableCell>
  <TableCell sx={{ color: "Black", fontSize: "20px" }}>
    {isNaN(columnTotals.kgWeight) ? "0.00" : columnTotals.kgWeight.toFixed(2)}
  </TableCell>
  <TableCell sx={{ color: "Black", fontSize: "20px" }}>
    {isNaN(columnTotals.katcha - dynamicWeight)
      ? "0.00"
      : (columnTotals.katcha - dynamicWeight).toFixed(2)}
  </TableCell>
  <TableCell></TableCell>
</TableRow>

              </TableBody>
            </Table>
            
          </TableContainer>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end" }}>
  <Button onClick={handleCloseModal} sx={{ backgroundColor: "red", color: "black" }}>
    Cancel
  </Button>
  <Button onClick={saveDataToDatabase} sx={{ backgroundColor: "green", color: "black" }}>
    Save
  </Button>
</DialogActions>

      </Dialog>
        </>
      )}

      
       


export default Melting;