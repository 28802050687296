import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from '@mui/icons-material/Print';
import { SiMicrosoftexcel } from "react-icons/si";
import { TablePagination, Checkbox } from "@mui/material";

import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Container,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const OrderManagement = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const handleRowSelection = (rowId) => {
    const selectedIndex = selectedRows.indexOf(rowId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, rowId);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelectedRows);
  };
  const tableData = [
    { id: 1, name: 'Product 1', quantity: 10, price: '$10' },
    { id: 2, name: 'Product 2', quantity: 5, price: '$15' },
    // Add more table data as needed
  ];
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box height={60} />
      <Typography variant="h6" component={"h6"} noWrap>
        Order Management
      </Typography>
      <Container maxWidth="xxl">
        <Box>
          <Card>
            <CardContent>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                {/* <Typography variant="h6" component="h6" noWrap> O.M</Typography> */}

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={3} sm={4} md={3}>
                    {/* Left-side search bar */}
                    <TextField
                      fullWidth
                      placeholder="Search"
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={8} md={9} textAlign="right">
                    {/* Right-side buttons */}
                    <Button
                      variant="contained"
                      startIcon={<FilterAltIcon />}
                      sx={{ mx: 1, backgroundColor: "#002884" }}
                    >
                      Process 
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<FilterAltIcon />}
                      sx={{ mx: 1, backgroundColor: "#002884" }}
                    >
                      Reset 
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<SiMicrosoftexcel />}
                      sx={{ mx: 1, backgroundColor: "green" }}
                    >
                      Excel
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<PictureAsPdfIcon />}
                      sx={{ mx: 1, backgroundColor: "#ba000d" }}
                    >
                      PDF
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<PrintIcon />}
                      sx={{ mx: 1, backgroundColor: "#ba000d" }}
                    >
                      Print
                    </Button>
                  </Grid>
                </Grid>

                <TabContext value={value}>
                  <TabList
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    sx={{ display: "flex" }}
                  >
                    {/* Add title also */}
                    <Tab
                      label={
                        <span>
                          Inward{" "}
                          <Button startIcon={<FilterAltIcon />}> </Button>
                        </span>
                      }
                      value="1"
                      sx={{ flex: 1 }}
                    />

                    <Tab
                      label={
                        <span>
                          Pending Approvals
                          <Button startIcon={<FilterAltIcon />}> </Button>
                        </span>
                      }
                      value="2"
                      sx={{ flex: 1.2 }}
                    />

                    <Tab
                      label={
                        <span>
                          Rejected{" "}
                          <Button startIcon={<FilterAltIcon />}> </Button>
                        </span>
                      }
                      value="3"
                      sx={{ flex: 1 }}
                    />

                    <Tab
                      label={
                        <span>
                          Completed{" "}
                          <Button startIcon={<FilterAltIcon />}> </Button>
                        </span>
                      }
                      value="4"
                      sx={{ flex: 1 }}
                    />

                    <Tab
                      label={
                        <span>
                          Return{" "}
                          <Button startIcon={<FilterAltIcon />}> </Button>
                        </span>
                      }
                      value="5"
                      sx={{ flex: 1 }}
                    />
                  </TabList>
                  <TabPanel value="1">
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedRows.includes(1)} // Replace '1' with the unique identifier of the row
                                onChange={() => handleRowSelection(1)} // Replace '1' with the unique identifier of the row
                              />
                            </TableCell>
                            <TableCell>S.No</TableCell>
                            <TableCell>WO.Number</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Material.Wgt</TableCell>
                            <TableCell>Purity</TableCell>
                            <TableCell>Expected Purity</TableCell>
                            <TableCell>Wastage</TableCell>
                            <TableCell>Charges</TableCell>
                            <TableCell>Type of work</TableCell>
                            <TableCell>No of Variety</TableCell>
                            <TableCell>Work Completion Date</TableCell>
                            <TableCell>PDF</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Add table rows here */}
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedRows.includes(1)} // Replace '1' with the unique identifier of the row
                                onChange={() => handleRowSelection(1)} // Replace '1' with the unique identifier of the row
                              />
                            </TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>Product 1</TableCell>
                            <TableCell>10</TableCell>
                            <TableCell>10</TableCell>
                            <TableCell>10</TableCell>
                            <TableCell>10</TableCell>
                            <TableCell>10</TableCell>
                            <TableCell>$10</TableCell>
                            <TableCell>$10</TableCell>
                            <TableCell>$10</TableCell>
                            <TableCell>$10</TableCell>
                            {/* <TableCell><Button startIcon={PictureAsPdfIcon}></Button> </TableCell> */}
                          </TableRow>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedRows.includes(1)} // Replace '1' with the unique identifier of the row
                                onChange={() => handleRowSelection(1)} // Replace '1' with the unique identifier of the row
                              />
                            </TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>Product 2</TableCell>
                            <TableCell>5</TableCell>
                            <TableCell>$15</TableCell>
                          </TableRow>
                          {/* Add more rows as needed */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={tableData.length} // Replace 'tableData' with the actual array of table data
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TabPanel>

                  {/* pending  */}
                  <TabPanel value="2">
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedRows.includes(1)} // Replace '1' with the unique identifier of the row
                              onChange={() => handleRowSelection(1)} // Replace '1' with the unique identifier of the row
                            />
                          </TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Price</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Add table rows here */}
                          <TableRow>
                          <TableCell padding="checkbox">
    <Checkbox
      checked={selectedRows.includes(1)} // Replace '1' with the unique identifier of the row
      onChange={() => handleRowSelection(1)} // Replace '1' with the unique identifier of the row
    />
  </TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>Product 1</TableCell>
                            <TableCell>10</TableCell>
                            <TableCell>$10</TableCell>
                          </TableRow>
                          <TableRow>
                          <TableCell padding="checkbox">
    <Checkbox
      checked={selectedRows.includes(1)} // Replace '1' with the unique identifier of the row
      onChange={() => handleRowSelection(1)} // Replace '1' with the unique identifier of the row
    />
  </TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>Product 2</TableCell>
                            <TableCell>5</TableCell>
                            <TableCell>$15</TableCell>
                          </TableRow>
                          {/* Add more rows as needed */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={tableData.length} // Replace 'tableData' with the actual array of table data
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TabPanel>

                  {/* reject */}
                  <TabPanel value="3">
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                          <TableCell padding="checkbox">
    <Checkbox
      checked={selectedRows.includes(1)} // Replace '1' with the unique identifier of the row
      onChange={() => handleRowSelection(1)} // Replace '1' with the unique identifier of the row
    />
  </TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Price</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Add table rows here */}
                          <TableRow>
                          <TableCell padding="checkbox">
    <Checkbox
      checked={selectedRows.includes(1)} // Replace '1' with the unique identifier of the row
      onChange={() => handleRowSelection(1)} // Replace '1' with the unique identifier of the row
    />
  </TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>Product 1</TableCell>
                            <TableCell>10</TableCell>
                            <TableCell>$10</TableCell>
                          </TableRow>
                          <TableRow>
                          <TableCell padding="checkbox">
    <Checkbox
      checked={selectedRows.includes(1)} // Replace '1' with the unique identifier of the row
      onChange={() => handleRowSelection(1)} // Replace '1' with the unique identifier of the row
    />
  </TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>Product 2</TableCell>
                            <TableCell>5</TableCell>
                            <TableCell>$15</TableCell>
                          </TableRow>
                          {/* Add more rows as needed */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={tableData.length} // Replace 'tableData' with the actual array of table data
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TabPanel>

                  {/* completed */}
                  <TabPanel value="4">
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                          <TableCell padding="checkbox">
    <Checkbox
      checked={selectedRows.includes(1)} // Replace '1' with the unique identifier of the row
      onChange={() => handleRowSelection(1)} // Replace '1' with the unique identifier of the row
    />
  </TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Price</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Add table rows here */}
                          <TableRow>
                          <TableCell padding="checkbox">
    <Checkbox
      checked={selectedRows.includes(1)} // Replace '1' with the unique identifier of the row
      onChange={() => handleRowSelection(1)} // Replace '1' with the unique identifier of the row
    />
  </TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>Product 1</TableCell>
                            <TableCell>10</TableCell>
                            <TableCell>$10</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>2</TableCell>
                            <TableCell>Product 2</TableCell>
                            <TableCell>5</TableCell>
                            <TableCell>$15</TableCell>
                          </TableRow>
                          {/* Add more rows as needed */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={tableData.length} // Replace 'tableData' with the actual array of table data
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TabPanel>

                  {/* return */}
                  <TabPanel value="5">
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                          <TableCell padding="checkbox">
    <Checkbox
      checked={selectedRows.includes(1)} // Replace '1' with the unique identifier of the row
      onChange={() => handleRowSelection(1)} // Replace '1' with the unique identifier of the row
    />
  </TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Price</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Add table rows here */}
                          <TableRow>
                          <TableCell padding="checkbox">
    <Checkbox
      checked={selectedRows.includes(1)} // Replace '1' with the unique identifier of the row
      onChange={() => handleRowSelection(1)} // Replace '1' with the unique identifier of the row
    />
  </TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>Product 1</TableCell>
                            <TableCell>10</TableCell>
                            <TableCell>$10</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>2</TableCell>
                            <TableCell>Product 2</TableCell>
                            <TableCell>5</TableCell>
                            <TableCell>$15</TableCell>
                          </TableRow>
                          {/* Add more rows as needed */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={tableData.length} // Replace 'tableData' with the actual array of table data
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TabPanel>
                </TabContext>
              </Box>
            </CardContent>

            {/* bottom button */}
            <CardActions>
              <Button variant="contained">Share</Button>
              <Button variant="contained">Print</Button>
            </CardActions>
          </Card>
        </Box>
      </Container>
    </>
  );
};

export default OrderManagement;
