import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink,Image } from "@react-pdf/renderer";

import { Checkbox } from "@mui/material";
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';

// import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import SearchIcon from "@mui/icons-material/Search";
import { Delete as DeleteIcon } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SaveIcon from "@mui/icons-material/Save"; // Import the SaveIcon
import axios from "axios";
import "./DM.css";



const PDFDocument = ({ savedItems }) => {
  const currentDate = new Date().toLocaleString();

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title1}>PSR-Silvers Enterprise</Text>
        <Text style={styles.title}>Saved Items</Text>
        <Text style={styles.date}>Date:{currentDate}</Text>
        <View style={styles.Table}>
          <View style={styles.TableHead}>
            <View style={styles.TableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>S.No</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>DesignName</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>DesignImage</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Customer Name</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Pieces</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Size</Text>
              </View>
            </View>
          </View>
          {savedItems.map((item, index) => (
  <View key={index} style={styles.TableRow}>
    <View style={styles.tableCol}>
      <Text>{index + 1}</Text>
    </View>
    <View style={styles.tableCol}>
      <Text>{item.DesignName}</Text>
    </View>
    <View style={styles.tableCol}>
      <Image src={item.DPimage} style={styles.image} />
    </View>
    <View style={styles.tableCol}>
      <Text>{item.CustomerName}</Text>
    </View>
    <View style={styles.tableCol}>
      <Text>{item.Pieces}</Text>
    </View>
    <View style={styles.tableCol}>
      <Text>{item.Size}</Text>
    </View>
  </View>
))}
        </View>
      </Page>
    </Document>
  );
          };

// Styles for the PDF template
const styles = StyleSheet.create({
 
  body: {
    padding: 20,
  },
  date: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: 'right',
  },
  title1: {
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
  title: {
    fontSize: 16,
    marginBottom: 10,
    textAlign: 'center',
  },
  Table: {
    marginTop: 10,
    borderWidth: 1,
    borderColor: '#000',
  },
  TableHead: {
    fontSize: 14,
  },
  TableRow: {
    fontSize: 12,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    backgroundColor: '#f2f2f2',
    padding: 5,
  },
  tableCol: {
    flex: 1,
    padding: 5,
  },
  tableCell: {
    fontWeight: 'bold',
  },
  item: {
    flexDirection: 'row',
    padding: 5,
  },
  image: {
    width: "50px", // Adjust the width as needed
    height: "50px", // Adjust the height as needed
  },
});

const DesignProcess = () => {
  const [load , setLoading] = useState(false);
  const [showPDF, setShowPDF] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [showSelectedItems, setShowSelectedItems] = useState(false); // State variable to track visibility of Selected Items field
  const [savedItems, setSavedItems] = useState([]);
  const [designs, setDesigns] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [selectedDesign, setSelectedDesign] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedDesignImage, setSelectedDesignImage] = useState("");
  const [cutomer, setCutomer] = useState("");
  const [selectedPsc, setSelectedPsc] = useState("");
  const [serialNumbers, setSerialNumbers] = useState(1);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedImageURL, setSelectedImageURL] = useState(null);

  const handleImageClick = (imageURL) => {
    setSelectedImageURL(imageURL);
  };
  
  const handlePrintPDF = () => {
    setShowPDF(true);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(savedItems.map((row) => row));
    }
    setSelectAll(!selectAll);
  };

  const handleRowSelect = (rowSNo) => {
    const updatedSelectedRows = selectedRows.includes(rowSNo)
      ? selectedRows.filter((SNo) => SNo !== rowSNo)
      : [...selectedRows, rowSNo];

    setSelectedRows(updatedSelectedRows);
  };

  const handleDeleteSelected = async () => {
    try {
      // Create an array of objects with the required structure
      const selectedRowsData = selectedRows.map((row) => ({
        DesignName: row.DesignName,
        Pieces: row.Pieces,
        Size: row.Size,
      }));

      // Make a POST request to the API for deleting selected items
      const response = await fetch("https://apipsr.algojaxon.com/dp/delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedRowsData),
      });

      // Check if the response status is OK (200)
      if (response.ok) {
        // After successful deletion, update your local savedItems state
        const updatedSavedItems = savedItems.filter(
          (row) => !selectedRows.includes(row)
        );
        setSavedItems(updatedSavedItems);

        // Clear the selection
        setSelectedRows([]);
      } else {
        console.error("Error deleting items:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting items:", error);
      console.log("Full error object:", error);
    }
  };

  const loadData = async () => {
    try {
      // Fetch design names
      setLoading(true);
      const designNamesResponse = await fetch(
        "https://apipsr.algojaxon.com/dc"
      );
      const designNamesData = await designNamesResponse.json();
      setDesigns(designNamesData);

      // Fetch sizes
      const sizesResponse = await fetch("https://apipsr.algojaxon.com/ps");
      const sizesData = await sizesResponse.json();
      setSizes(sizesData);

      // Fetch saved items
      const savedItemsResponse = await axios.get(
        "https://apipsr.algojaxon.com/dp"
      );
      setSavedItems(savedItemsResponse.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    loadData(); // Initial data load
    setTimeout(() => {
      console.log('Setting loading to false...');
      setLoading(false); // Set loading to false after delay
    }, 2000);
  }, []);

  const handleDesignChange = (event) => {
    const selectedDesign = event.target.value;
    setSelectedDesign(selectedDesign);

    // Fetch the image URL for the selected design
    const selectedDesignData = designs.find(
      (design) => design.DesignName === selectedDesign
    );
    setSelectedDesignImage(
      selectedDesignData ? selectedDesignData.DesignURL : ""
    );
  };

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleAddItemClick = () => {
    if (selectedDesign && selectedSize && selectedPsc) {
      const newItem = {
        SNo: serialNumbers, // Use the current value of serialNumbers
        DesignName: selectedDesign,
        CustomerName:cutomer,
        DesignURL:selectedDesignImage,
        psc: selectedPsc,
        size: selectedSize,
      };
      setSelectedItems([...selectedItems, newItem]);
      setSerialNumbers(serialNumbers + 1); // Increment the serial number
      setShowSelectedItems(true);
    }
  };

  const handleDeleteItem = (sNo) => {
    const updatedItems = selectedItems.filter((item) => item.SNo !== sNo);
    setSelectedItems(updatedItems);
  };

  const [tableData, setTableData] = useState([]); // State variable for table data

  const handleSave = () => {
    const url = "https://apipsr.algojaxon.com/dp";
    setLoading(true);
    const requestData = selectedItems.map((item) => ({
      DesignName: item.DesignName,
      CustomerName:item.CustomerName,
      DPimage: item.DesignURL,
      Pieces: parseInt(item.psc),
      Size: parseFloat(item.size),
    }));

    axios
      .post(url, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Response data:", response.data);

        // Handle successful response, e.g., update UI or show a success message

        // Clear selected items to reset selection
        setSelectedItems([]);
        setLoading(true);

        // Refresh data by updating the state variable
        setTableData([...tableData, ...requestData]);
        loadData(); // Assuming tableData is an array of objects
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error saving selected items:", error.message);
        // Handle error, e.g., show an error message to the user
      });
  };

  return (
    <>
      {load ? (
        <div>
          <div className="loading-screen">
            <div className="lds-spinner-container">
              <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Box sx={{ height: "calc(100vh - 64px)", overflow: "auto" }} mt={10}>
          <Typography variant="h6" component="h6" noWrap>
                      Designs Process
                    </Typography>
            <Container maxWidth="xxl">
              <Box mt={2}>
                <Card>
                  <CardContent>
                    {" "}                    
                    <Box sx={{ mt: 2, overflow: "auto" }}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ backgroundColor: "grey.200" }}>
                                Design Name
                              </TableCell>
                              <TableCell sx={{ backgroundColor: "grey.200" }}>
                                Customer Name
                              </TableCell>
                              <TableCell sx={{ backgroundColor: "grey.200" }}>
                                Design Image
                              </TableCell>
                              <TableCell sx={{ backgroundColor: "grey.200" }}>
                                PSC
                              </TableCell>
                              <TableCell sx={{ backgroundColor: "grey.200" }}>
                                Size
                              </TableCell>
                              <TableCell
                                sx={{ backgroundColor: "grey.200" }}
                                align="right"
                              >
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <FormControl>
                                  <Select
                                    value={selectedDesign}
                                    onChange={handleDesignChange}
                                  >
                                    {designs.map((design) =>
                                      // Check if the design status is "Active" before rendering the MenuItem
                                      design.Status === "Active" ? (
                                        <MenuItem
                                          key={design.id}
                                          value={design.DesignName}
                                        >
                                          {design.DesignName}
                                        </MenuItem>
                                      ) : null
                                    )}
                                  </Select>
                                </FormControl>
                              </TableCell>

                              <TableCell>
                                <FormControl>
                                  <TextField
                                    variant="outlined"
                                    placeholder="Customer Name"
                                    type="text"
                                    fontSize="18px"                                
                                    value={cutomer}
                                    onChange={(event) =>
                                      setCutomer(event.target.value)
                                    }
                                  />
                                </FormControl>
                              </TableCell>

                              <TableCell>
                                {selectedDesignImage && (
                                  <img
                                    src={selectedDesignImage}
                                    alt="Design"
                                    width="100" 
                                    onClick={() => handleImageClick(selectedDesignImage)}                                 
                                  />
                                )}
                              </TableCell>

                              <TableCell>
                                <FormControl>
                                  <TextField
                                    variant="outlined"
                                    placeholder="Enter PSC"
                                    type="number"
                                    fontSize="18px"                                
                                    value={selectedPsc}
                                    onChange={(event) =>
                                      setSelectedPsc(event.target.value)
                                    }
                                  />
                                </FormControl>
                              </TableCell>

                              <TableCell>
                                <FormControl>
                                  <Select
                                    value={selectedSize}
                                    onChange={handleSizeChange}
                                  >
                                    {sizes.map((size) => (
                                      <MenuItem
                                        key={size.id}
                                        value={size.PsizeValue}
                                      >
                                        {size.PsizeValue}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </TableCell>

                              <TableCell align="right">
                                <IconButton
                                  aria-label="Add"
                                  onClick={handleAddItemClick}
                                >
                                  <AddCircleIcon />{" "}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                            {/* Add more rows here */}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>


                    
                    {/* Selected List */}
                    {showSelectedItems && (
                      <>
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          mt={2}
                          sx={{ overflow: "auto" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={handleSave}
                          >
                            Save Selected
                          </Button>
                        </Box>

                        <Box mt={2}>
                          <Typography variant="h6" component="h6" noWrap>
                            Selected Items:
                          </Typography>
                          <TableContainer>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Design</TableCell>
                                  <TableCell>Customer Name</TableCell>
                                  <TableCell>Image</TableCell>
                                  <TableCell>PSC</TableCell>
                                  <TableCell>Size</TableCell>
                                  <TableCell align="right">Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectedItems.map((item) => (
                                  <TableRow key={item.SNo}>
                                    <TableCell>{item.DesignName}</TableCell>
                                    <TableCell>{item.CustomerName}</TableCell>
                                    <TableCell>
                        <img
                          src={item.DesignURL}
                          alt="Design"
                          width="50"
                          height="50"
                          onClick={() => handleImageClick(item.DesignURL)}
                        /> </TableCell>
                                    <TableCell>{item.psc}</TableCell>
                                    <TableCell>{item.size}</TableCell>
                                    <TableCell align="right">
                                      <IconButton
                                        onClick={() =>
                                          handleDeleteItem(item.SNo)
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Box>
            </Container>

            <Container maxWidth="xxl">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" component="div" gutterBottom>
                        Saved Items
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "10px",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<DeleteIcon />}
                            onClick={handleDeleteSelected}
                          >
                            Delete
                          </Button>

                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<PrintRoundedIcon />}
                             onClick={handlePrintPDF}
                          >
                            Print
                          </Button>
                          {showPDF && (
        <PDFDownloadLink document={<PDFDocument savedItems={savedItems} />} fileName="saved_items.pdf">
          {({ blob, url, loading, error }) =>
            loading ? "Loading..." : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<PrintRoundedIcon />}
                onClick={() => window.open(url, "_blank")}
              >
                Open PDF
              </Button>
            )
          }
        </PDFDownloadLink>
      )}
       
                        </div>
                      </Typography>

                      <TableContainer component={Paper}>
                        <Table size="small">
                          <TableHead sx={{ backgroundColor: "grey.200" }}>
                            <TableRow>
                              <TableCell style={{ width: "5%" }}>SNo</TableCell>
                              <TableCell style={{ width: "5%" }}>
                                Design Name
                              </TableCell>
                              <TableCell style={{ width: "5%" }}>
                                Customer Name
                              </TableCell>
                              <TableCell style={{ width: "5%" }}>
                                Design Image
                              </TableCell>
                              <TableCell style={{ width: "5%" }}>PSC</TableCell>
                              <TableCell style={{ width: "5%" }}>
                                Size
                              </TableCell>
                              <TableCell style={{ width: "5%" }}>
                                <label>
                                  <Checkbox
                                    name="Select Delete"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                  />
                                  Select Delete
                                </label>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {savedItems.map((row, index) => (
                              <TableRow key={row.SNo}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.DesignName}</TableCell>
                                <TableCell>{row.CustomerName}</TableCell>
                                <TableCell> 
                                <img
                          src={row.DPimage}
                          alt="Design"
                          width="100"
                          height="100"
                          onClick={() => handleImageClick(row.DPimage)}
                        /> </TableCell>
                        {selectedImageURL && (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    }}
    onClick={() => setSelectedImageURL(null)} // Close the full-screen image
  >
    <img
      src={selectedImageURL}
      alt="Design"
      style={{ maxWidth: '90vw', maxHeight: '90vh', cursor: 'pointer' }}
    />
    <button
      onClick={() => setSelectedImageURL(null)}
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: 'white',
        color: 'black',
        border: 'none',
        cursor: 'pointer',
      }}
    >
      Close
    </button>
  </div>
)}

                                <TableCell>{row.Pieces}</TableCell>
                                <TableCell>{row.Size}</TableCell>
                                <TableCell>
                                  <Checkbox
                                    checked={selectedRows.includes(row)}
                                    onChange={() => handleRowSelect(row)}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

            </Container>
          </Box>

          
        </>
      )}
    </>
  );
};

export default DesignProcess;
