import { useState } from "react";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import BadgeIcon from "@mui/icons-material/Badge";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import OrderManagement from "../../Pages/order_management/OrderManagement";
import DesignModels from "../../Pages/designs/DesignModel";
import WorkersRegistration from "../../Pages/worker_registration/WorkersRegistration";
import WorkOrderSetting from "../../Pages/workorder_settings/WorkOrderSetting";
import WorkOrderReturns from "../../Pages/workorder_returns/WorkOrderReturns";
import "./Sidebar.css";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import { Collapse } from "@mui/material";
import Design from "../dashboard/sub/Design";
import Order from "../dashboard/sub/Order";
import Workers from "../dashboard/sub/Workers";
import Customer from "../dashboard/sub/Customer";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import WorkingProcess from '../../Pages/order_management/WorkingProcess';
import DesignProcess from "../../Pages/designs/DesignModel";
import Melting from "../../Pages/melting/Melting";
import { GiMeltingMetal } from 'react-icons/gi';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar({ onLogout }) {
  const [openNested, setOpenNested] = useState(false);

  const [openNestedOrderManagement, setOpenNestedOrderManagement] = useState(false);

  
  const handleNestedClicks = () => {
    setOpenNested(!openNested);
    setOpenNestedOrderManagement(false);
  };




  const handleLogout = () => {
    // Clear any user-related data from localStorage or perform any other necessary actions
    localStorage.removeItem("accessToken"); // Example: remove access token from localStorage

    // Call the onLogout prop function
    onLogout();
  };

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [menudata, setMenudata] = useState("Home"); // Removed unused setMenudata

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" elevation={4} sx={{ background: "#4d84e2" }}>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  setOpen(!open);
                }}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                {/* <img src="/img/logo.png" alt="" width={70} height={40}/> */}
                Ammasi Silver Enterprise
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                color="inherit"
                aria-label="logout"
                edge="end"
                sx={{ marginRight: 0.5 }}
              >
                {/* <NotificationsActiveIcon/> */}
              </IconButton>

              <Button
                variant=" contained"
                noWrap
                component="div"
                onClick={handleLogout}
                startIcon={<LogoutIcon />}
                sx={{ backgroundColor: "Black" }}
              >
                Logout
              </Button>
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {/* sidebar color adding */}
          <List sx={{ background: "" }}>.
                {/* Master */}
            <ListItem
              disablePadding
              sx={{ display: "block" }}              
            >
              <ListItemButton
              onClick={handleNestedClicks}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    paddingTop: 1.5,
                  }}
                >
                  {" "}
                  <AdminPanelSettingsIcon sx={{ color: "black" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                        paddingTop: 1.5,
                      }}
                    >
                      {"Master Page"}
                    </Typography>
                  }
                  sx={{
                    opacity: open ? 1 : 0,
                  }}
                />
              </ListItemButton>

              <Collapse in={openNested} timeout="auto" unmountOnExit>
    <List component="div" disablePadding>
                  {/* sub design */}
                  <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => setMenudata("Design Creation")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: openNested ? "initial" : "center",
                        px: 4.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: openNested ? 3 : "auto",
                          justifyContent: "center",
                          paddingTop: 1,
                        }}
                      >
                        <DesignServicesIcon sx={{ color: "black" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "14px",
                              fontFamily: "sans-serif",
                              paddingTop: 1.5,
                            }}
                          >
                            {"Design Creation"}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>

                  {/* sub worker  */}
                  {/* <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => setMenudata("Workers")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: openNested ? "initial" : "center",
                        px: 4.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: openNested ? 3 : "auto",
                          justifyContent: "center",
                          paddingTop: 1,
                        }}
                      >
                        <BadgeIcon sx={{ color: "black" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "14px",
                              fontFamily: "sans-serif",
                              paddingTop: 1.5,
                            }}
                          >
                            {"Workers"}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem> */}

                   {/* sub customer */}
                  {/* <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => setMenudata("Customer")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: openNested ? "initial" : "center",
                        px: 4.5,
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "14px",
                              fontFamily: "sans-serif",
                              paddingTop: 1.5,
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: openNested ? 3 : "auto",
                                justifyContent: "center",
                                paddingTop: 0,
                              }}
                            >
                              <GroupAddIcon sx={{ color: "black" }} />
                            </ListItemIcon>
                            {"Customer"}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem> */}

                    {/* sub order */}
                  {/* <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => setMenudata("Order")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: openNested ? "initial" : "center",
                        px: 4.5,
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "14px",
                              fontFamily: "sans-serif",
                              paddingTop: 1.5,
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: openNested ? 3 : "auto",
                                justifyContent: "center",
                                paddingTop: 0,
                              }}
                            >
                              <BookmarkBorderIcon sx={{ color: "black" }} />
                            </ListItemIcon>
                            {"Order"}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem> */}
                </List>
              </Collapse>
            </ListItem>

 {/* Melting Process */}
 <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={() => setMenudata("Melting Process")}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    paddingTop: 1.5,
                  }}
                >
                  <GiMeltingMetal
                    sx={{
                      color: "#051937",
                      "&:hover": {
                        color: "#2186c2", // Modify the color to the desired hover color
                      },
                    }}
                  />
                 
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "14px", // Adjust the font size as desired
                        fontFamily: "sans-serif",
                        paddingTop: 1.5,
                      }}
                    >
                      {"Melting Process"}
                    </Typography>
                  }
                  sx={{
                    opacity: open ? 1 : 0,
                  }}
                />
              </ListItemButton>
            </ListItem>


                {/* Order Management */}
            {/* <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          onClick={() => setOpenNestedOrderManagement(!openNestedOrderManagement)}
          sx={{
            minHeight: 48,
            justifyContent: openNestedOrderManagement ? "initial" : "center",
            px: 2.5,
          }}
        >
              
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    paddingTop: 1.5,
                  }}
                >
                  {" "}
                  <PlaylistAddIcon sx={{ color: "black" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                        paddingTop: 1.5,
                      }}
                    >
                      {"Order Management"}
                    </Typography>
                  }
                  sx={{
                    opacity: open ? 1 : 0,
                  }}
                />
              </ListItemButton>

              <Collapse in={openNestedOrderManagement} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
                  <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => setMenudata("WorkingProcess")}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: openNested ? "initial" : "center",
                        px: 4.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: openNested ? 3 : "auto",
                          justifyContent: "center",
                          paddingTop: 1,
                        }}
                      >
                        <DesignServicesIcon sx={{ color: "black" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "14px",
                              fontFamily: "sans-serif",
                              paddingTop: 1.5,
                            }}
                          >
                            {"WorkingProcess"}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>

                  
                </List>
              </Collapse>
            </ListItem> */}

                {/* Design Process */}
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={() => setMenudata("Design Process")}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    paddingTop: 1.5,
                  }}
                >
                  <ManageAccountsIcon
                    sx={{
                      color: "#051937",
                      "&:hover": {
                        color: "#2186c2", // Modify the color to the desired hover color
                      },
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "14px", // Adjust the font size as desired
                        fontFamily: "sans-serif",
                        paddingTop: 1.5,
                      }}
                    >
                      {"Design Process"}
                    </Typography>
                  }
                  sx={{
                    opacity: open ? 1 : 0,
                  }}
                />
              </ListItemButton>
            </ListItem>

                {/*Worker Registration  */}
            {/* <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={() => setMenudata("Workers Registration")}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    paddingTop: 1.5,
                  }}
                >
                  <HowToRegIcon
                    sx={{
                      color: "#051937",
                      "&:hover": {
                        color: "#2186c2", // Modify the color to the desired hover color
                      },
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "14px", // Adjust the font size as desired
                        fontFamily: "sans-serif",
                        paddingTop: 1.5,
                      }}
                    >
                      {"Workers Registration"}
                    </Typography>
                  }
                  sx={{
                    opacity: open ? 1 : 0,
                  }}
                />
              </ListItemButton>
            </ListItem> */}

              {/* Work Order Setting */}
            {/* <ListItem
              disablePadding
              sx={{ display: "block", "&:hover": { color: "#2186c2" } }}
              onClick={() => setMenudata("Work Orders Settings")}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    paddingTop: 1.5,
                  }}
                >
                  <EngineeringIcon
                    sx={{
                      color: "#051937",
                      "&:hover": {
                        color: "#2186c2", // Modify the color to the desired hover color
                      },
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "14px", // Adjust the font size as desired
                        fontFamily: "sans-serif",
                        paddingTop: 1.5,
                      }}
                    >
                      {"Work Orders Settings"}
                    </Typography>
                  }
                  sx={{
                    opacity: open ? 1 : 0,
                  }}
                />
              </ListItemButton>
            </ListItem> */}

                  {/* work order return  */}
            {/* <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={() => setMenudata("Work Orders Returns")}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    paddingTop: 1.5,
                  }}
                >
                  <AssignmentReturnIcon sx={{ color: "#051937" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "14px", // Adjust the font size as desired
                        fontFamily: "sans-serif",
                        paddingTop: 1.5,
                      }}
                    >
                      {"Work Orders Returns"}
                    </Typography>
                  }
                  sx={{
                    opacity: open ? 1 : 0,
                  }}
                />
              </ListItemButton>
            </ListItem> */}
          </List>
          <Divider />
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {/* {menudata === "Design Process" ? <DesignProcess /> : null} */}
          {/* {menudata === "Order Management" ? <OrderManagement /> : null} */}
          {menudata === "Design Process" ? <DesignModels /> : null}
          {/* {menudata === "Workers Registration" ? <WorkersRegistration /> : null}
          {menudata === "Work Orders Settings" ? <WorkOrderSetting /> : null}
          {menudata === "Work Orders Returns" ? <WorkOrderReturns /> : null} */}
          {menudata === "Design Creation" ? <Design /> : null}
          {menudata === "Melting Process" ? <Melting/> : null}

          {/* {menudata === "Order" ? <Order /> : null}
          {menudata === "Workers" ? <Workers /> : null}
          {menudata === "Customer" ? <Customer/> : null}
          {menudata === "WorkingProcess" ? <WorkingProcess/> : null} */}
           

        </Box>
      </Box>
    </>
  );
}
