import React, { Component } from 'react';
import '../logs/logs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpMode: false,
      username: '',
      password: '',
      loginError: null,
      showPassword: false, // Add state to toggle password visibility
    };
  }

  handleSignUpClick = () => {
    this.setState({ signUpMode: true });
  };

  handleSignInClick = () => {
    this.setState({ signUpMode: false });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { username, password } = this.state;

    // Make an API request to validate the username and password
    try {
      const response = await fetch('https://apipsr.algojaxon.com/log');
      const data = await response.json();

      const matchingUser = data.find(user => user.Username === username && user.Password === password);
      if (matchingUser) {
        // Successful login, invoke the onLogin prop
        this.props.onLogin();
      } else {
        // Display an error message for invalid credentials
        this.setState({ loginError: 'Invalid username or password' });
      }
    } catch (error) {
      console.error('Error fetching API:', error);
      // Handle error appropriately, e.g., show an error message to the user
    }
  };

  render() {
    const { signUpMode, username, password, loginError, showPassword } = this.state;

    return (
      <div className={`container ${signUpMode ? 'sign-up-mode' : ''}`}>
      <div className="forms-container">
        <div className="signin-signup">
          <form action="#" className="sign-in-form" onSubmit={this.handleSubmit}>
            <h2 className="title">Sign in</h2>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <input
                type="text"
                placeholder="Username"
                name="username"
                value={username}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="input-field password-input-field">
  <i className="fas fa-lock"></i>
  <input
    type={showPassword ? 'text' : 'password'}
    placeholder="Password"
    name="password"
    value={password}
    onChange={this.handleInputChange}
  />
  <span className="password-toggle-icon" onClick={this.togglePasswordVisibility}>
    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
  </span>
</div>

            <input type="submit" value="Login" className="btn solid" />
            {loginError && <p className="error-message">{loginError}</p>}
          </form>
          {/* Add instructions for using the web app */}
        </div>
      </div>

      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            {/* Content for left panel */}
          </div>
          <img src="img/log.svg" className="image" alt="" />
        </div>
        <div className="panel right-panel">
          <div className="content">
            {/* Content for right panel */}
          </div>
          <img src="img/register.svg" className="image" alt="" />
        </div>
      </div>
    </div>
    );
  }
}
