import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OrderManagement from './Pages/order_management/OrderManagement';
import WorkOrderReturns from './Pages/workorder_returns/WorkOrderReturns';
import WorkOrderSetting from './Pages/workorder_settings/WorkOrderSetting';
import WorkersRegistration from './Pages/worker_registration/WorkersRegistration';
import Sidebar from './components/sidebar/Sidebar';
import Design from './components/dashboard/sub/Design';
import Workers from './components/dashboard/sub/Workers';
import Order from './components/dashboard/sub/Order';
// import Home from './components/dashboard/Home';
import DesignModel from './Pages/designs/DesignModel';
import './App.css';
import Melting from './Pages/melting/Melting';
function App({ onLogout }) {
  const [load, setLoading] = useState(true); // Initialize loading state to true

  useEffect(() => {
    // Simulate a loading delay (remove this in your actual code)
    setTimeout(() => {
      console.log('Setting loading to false...');
      setLoading(false); // Set loading to false after delay
    }, 2000);
  }, []);

  return (
    <Router>
      {/* Render the Sidebar */}
      <Sidebar onLogout={onLogout} />

      {/* Render the Routes with loading condition */}
      {load ? (
        /* Render the loading screen if loading is true */
        <div className="loading-screen">
        <div className="lds-spinner-container">
          <div className="lds-spinner">  
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      </div>
      
    </div>
        
      ) : (
        /* Render the actual content if loading is false */
        <Routes>
          {/* <Route path="/Home" element={<Home />} /> */}
          <Route path="/OrderManagement" element={<OrderManagement />} />
          <Route path="/DesignModels" element={<DesignModel />} />
          <Route path="/WorkersRegistration" element={<WorkersRegistration />} />
          <Route path="/WorkOrderSetting" element={<WorkOrderSetting />} />
          <Route path="/WorkOrderReturns" element={<WorkOrderReturns />} />
          <Route path="/Design" element={<Design />} />
          <Route path="/Order" element={<Order />} />
          <Route path="/Workers" element={<Workers />} />
          <Route path="/Melting" element={<Melting />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
