import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Login from './Pages/logs/Login';

const history = createBrowserHistory();

const Root = () => {
  // Use localStorage to maintain authentication state
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const [isAuth, setIsAuth] = useState(isAuthenticated);

  const handleLogin = () => {
    localStorage.setItem('isAuthenticated', 'true');
    setIsAuth(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    setIsAuth(false);
  };


  return (
    <React.StrictMode>
      {isAuth ? (
        <App onLogout={handleLogout} />
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

reportWebVitals();
