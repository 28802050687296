import React, { useState } from 'react';
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TablePagination
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import SearchIcon from '@mui/icons-material/Search';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

const Workers = () => {
  const handleEdit = (id) => {
    // Handle edit logic for the specified id
  };

  const handleDelete = (id) => {
    // Handle delete logic for the specified id
  };

  const [open, setOpen] = useState(false);
  const [typeOfWork, setTypeOfWork] = useState('');
  const [activeTab, setActiveTab] = useState('active');
  const [value, setValue] = useState("1");
  const [page, setPage] = useState(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(5); // Number of rows per page

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTypeOfWorkChange = (event) => {
    setTypeOfWork(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const data = [
    { id: 1, name: 'Worker 1', typeOfWork: 'Work 1', gram: '123' },
    // Add more data for active workers if needed
  ];

  const inactiveData = [
    { id: 1, name: 'Worker 2', typeOfWork: 'Work 2' },
    // Add more data for inactive workers if needed
  ];

  return (
    <>
      <Box height={60} />
      <Typography variant="h6" component="h6" noWrap>
        Workers Details
      </Typography>
      <Container maxWidth="xxl">
        <Box mt={2}>
          <Card>
            <CardContent>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      placeholder="Search"
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} textAlign="right">
                    <Button
                      variant="contained"
                      startIcon={<GroupAddIcon />}
                      sx={{ backgroundColor: 'green' }}
                      onClick={handleClickOpen}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              {/* Add the tabs */}
              <TabContext value={value}>
                <TabList
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  sx={{ display: "flex" }}
                >
                  <Tab label="Active" value="1" sx={{ flex: 1 }} />
                  <Tab label="Inactive" value="2" sx={{ flex: 1.2 }} />
                </TabList>
              </TabContext>

              {/* Add the tables */}
              {value === '1' && (
                <>
                  <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "grey.200" }}>
                          <TableCell>S.No</TableCell>
                          <TableCell>Worker Name</TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              Type Of Work
                              <IconButton size="small">
                                <FilterAltIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                          <TableCell>Gram</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{row.id}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.typeOfWork}</TableCell>
                              <TableCell>{row.gram}</TableCell>
                              <TableCell>
                                <IconButton onClick={() => handleEdit(row.id)}>
                                  <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDelete(row.id)}>
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {/* Add the table pagination */}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}

              {value === '2' && (
                <>
                  <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                      <TableHead>
                        <TableRow  sx={{ backgroundColor: "grey.200" }}>
                          <TableCell >S.No</TableCell>
                          <TableCell>Worker Name</TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              Type Of Work
                              <IconButton size="small">
                                <FilterAltIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {inactiveData
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{row.id}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.typeOfWork}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {/* Add the table pagination */}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={inactiveData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}
            </CardContent>

            {/* Bottom buttons */}
            <CardActions>
              {/* <Button variant="contained">Share</Button>
              <Button variant="contained">Print</Button> */}
            </CardActions>
          </Card>
        </Box>
      </Container>

      {/* Modal for form */}
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add Workers</DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name Of Worker"
              type="text"
              variant="standard"
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="wid"
              label="Workers ID"
              type="text"
              variant="standard"
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="grm"
              label="Gram"
              type="text"
              variant="standard"
              fullWidth
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="type-of-work-label">Type of Work</InputLabel>
              <Select
                labelId="type-of-work-label"
                id="type-of-work"
                value={typeOfWork}
                onChange={handleTypeOfWorkChange}
                variant="standard"
              >
                <MenuItem value="work1">Work 1</MenuItem>
                <MenuItem value="work2">Work 2</MenuItem>
                <MenuItem value="work3">Work 3</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleClose}>Submit</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Workers;
