import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress
} from "@mui/material";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import SearchIcon from "@mui/icons-material/Search";
import RestoreIcon from "@mui/icons-material/Restore";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CloseIcon from '@mui/icons-material/Close';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import axios from "axios";



const Design = () => {
  const [selectedImageURL, setSelectedImageURL] = useState(null);

  const [load , setLoading] = useState(false);
  // const [showLargeImage, setShowLargeImage] = useState(false);
  const [editedDesign, setEditedDesign] = useState(null);
  const [open, setOpen] = useState(false);
  const [designId, setDesignId] = useState(
    editedDesign ? editedDesign.DesignID : ""
  );
  const [designName, setDesignName] = useState(
    editedDesign ? editedDesign.DesignName : ""
  );
  const [designImage, setDesignImage] = useState(
    editedDesign ? editedDesign.DesignURL : ""
  );

  const [selectedFile, setSelectedFile] = useState(null);

 
  
  const [value, setValue] = useState("1");
  const [tab2Data, setTab2Data] = useState([]);
  const [designData, setDesignData] = useState([]);
  const existingData = [];

  const [updateMessage, setUpdateMessage] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(null);

  const [saveMessage, setSaveMessage] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(null);

  const [uploadProgress, setUploadProgress] = useState(0);

  const successStyle = {
    color: "green",
  };

  const errorStyle = {
    color: "red",
  };



  const handleEdit = (row) => {
    setEditedDesign(row);
    setDesignId(row.DesignID); // Populate the DesignID field
    setDesignName(row.DesignName);
  setDesignImage(row.DesignURL);
    setOpen(true); // Open the dialog for editing
  };

  const handleDeactivate = async (SNo) => {
    try {
      const response = await axios.post(
        `https://apipsr.algojaxon.com/dc/deactivate/${SNo}`
      );

      if (response.status === 200) {
        const deactivatedDesign = designData.find((item) => item.SNo === SNo);
        setDesignData((prevDesignData) =>
          prevDesignData.filter((item) => item.SNo !== SNo)
        );
        setTab2Data((prevTab2Data) => [...prevTab2Data, deactivatedDesign]);
        console.log("Design deactivated successfully");
      } else {
        console.error("Failed to deactivate design");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleActivate = async (SNo) => {
    try {
      const response = await axios.post(
        `https://apipsr.algojaxon.com/dc/activate/${SNo}`
      );

      if (response.status === 200) {
        // Find the index of the activated design in tab2Data
        const index = tab2Data.findIndex((item) => item.SNo === SNo);

        if (index !== -1) {
          // Get the activated design from tab2Data
          const activatedDesign = tab2Data[index];

          // Remove the activated design from tab2Data
          const updatedTab2Data = tab2Data.filter((item) => item.SNo !== SNo);

          // Update designData with the activated design
          setDesignData((prevDesignData) => [
            ...prevDesignData,
            activatedDesign,
          ]);

          // Update tab2Data with the updated array
          setTab2Data(updatedTab2Data);

          console.log("Design activated successfully");
        } else {
          console.error("Design not found in tab2Data");
        }
      } else {
        console.error("Failed to activate design");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleClickOpen = () => {
    setEditedDesign(null); // Reset to null when adding a new design
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (!editedDesign) {
      setDesignId(""); // Reset the DesignID field only when adding new design
    }
    setUpdateMessage(null);
    setUpdateSuccess(null);
    setSaveMessage(null);
    setSaveSuccess(null);
  };

  const handleDesignIdChange = (event) => {
    setDesignId(event.target.value);
  };

  const handleDesignNameChange = (event) => {
    setDesignName(event.target.value);
  };

  const handleDesignImageChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  

  // const handleDelete = async (designID) => {
  //   try {
  //     // Make an API call to deactivate the design
  //     const response = await axios.post(`https://apipsr.algojaxon.com/dc/delete/${designID}`);

  //     if (response.status === 200) {
  //       // If deactivation was successful (status code 200), update the stat

  //       console.log("Design Delete successfully");
  //       loadData();
  //     } else {
  //       // Handle error if the response status is not 200
  //       console.error("Failed to deactivate design");
  //     }
  //   } catch (error) {
  //     // Handle API call error
  //     console.error("An error occurred:", error);
  //   }
  // };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkForDuplicateData = async (newData) => {
    // You need to implement the logic to check for duplicates
    // This could involve making a request to your API or checking local data
    // Compare newData with existing data and return true if a duplicate is found

    // Example: Assuming your data is an array of existingData
    const isDuplicate = existingData.some(
      (item) =>
        item.DesignID === newData.DesignID &&
        item.DesignName === newData.DesignName &&
        item.DesignURL === newData.DesignURL
    );

    return isDuplicate;
  };

// Code for sending POST request with image file in React
const handleSave = async () => {
  try {
    setLoading(true);

    const newData = {
      DesignID: designId,
      DesignName: designName,     
     DesignURL: '', // Leave this empty for now, it will be filled later
     Status:"Active",
    };

    const isDuplicate = await checkForDuplicateData(newData);

    if (isDuplicate) {
      // Handle duplicate data
      // ...

      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('DesignID', designId);
    formData.append('DesignName', designName);
    formData.append('Image', selectedFile);

    // Step 1: Upload the image using the separate API
    const imageResponse = await axios.post(
      'https://apipsr.algojaxon.com/dc/SaveFile',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress); // Update the upload progress state
        },
      }
    );

    if (imageResponse.status === 200) {
      const filename = imageResponse.data; // Assuming the response contains the filename
      const apiBaseUrl = 'https://apipsr.algojaxon.com/wwwroot/Photos';
    
      const imageURL = `${apiBaseUrl}/${filename}`;
    
      newData.DesignURL = imageURL;

      // Step 2: Send design data including the image URL to the main API
      const mainAPIResponse = await axios.post(
        'https://apipsr.algojaxon.com/dc',
        newData
      );

      if (mainAPIResponse.status === 200) {
        // Update the designData state or perform any other necessary updates
        // ...
        reloadData(); // Refresh the data
        handleClose();
        resetFormFields();
        setSaveMessage('Design saved successfully');
        setSaveSuccess(true);
        
      } else {
        setSaveMessage('Failed to save design');
        setSaveSuccess(false);
      }
    } else {
      setSaveMessage('Failed to upload image');
      setSaveSuccess(false);
    }

    setLoading(false);
  } catch (error) {
    setSaveMessage('An error occurred while saving');
    setSaveSuccess(false);

    console.error('An error occurred while saving:', error);
    setLoading(false);
  }
};

  
const handleUpdate = async () => {
  try {
    setLoading(true);

    const updatedData = {
      DesignID: designId,
      DesignName: designName,
      Status : "Active",
      DesignURL: editedDesign.DesignURL, // Preserve existing image URL
    };

    const formData = new FormData();
    formData.append('DesignID', designId);
    formData.append('DesignName', designName);
    formData.append('Image', selectedFile);

    // Upload the new image if selected
    if (selectedFile) {
      const imageResponse = await axios.post(
        'https://apipsr.algojaxon.com/dc/SaveFile',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress); // Update the upload progress state
          },
        }
      );

      if (imageResponse.status === 200) {
        const filename = imageResponse.data;
        const apiBaseUrl = 'https://apipsr.algojaxon.com/wwwroot/Photos';

        updatedData.DesignURL = `${apiBaseUrl}/${filename}`;        
      } else {
        setSaveMessage('Failed to upload image');
        setSaveSuccess(false);
        setLoading(false);
        return;
      }
    }

    // Update the design using the main API
    const response = await axios.post(
      `https://apipsr.algojaxon.com/dc/update/${editedDesign.DesignID}`,
      updatedData
    );

    if (response.status === 200) {
      // Update the designData state or perform any other necessary updates
      // ...
      reloadData(); // Refresh the data
      handleClose();
      // resetFormFields();
      setSaveMessage('Design updated successfully');
      setSaveSuccess(true);
    } else {
      setSaveMessage('Failed to update design');
      setSaveSuccess(false);
    }

    setLoading(false);
  } catch (error) {
    setSaveMessage('An error occurred while updating');
    setSaveSuccess(false);

    console.error('An error occurred while updating:', error);
    setLoading(false);
  }
};


  // ...

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await axios.get("https://apipsr.algojaxon.com/dc");
      const allDesigns = response.data;
      const activeDesigns = allDesigns.filter(
        (item) => item.Status === "Active"
      );
      const inactiveDesigns = allDesigns.filter(
        (item) => item.Status === "Inactive"
      );
      setDesignData(activeDesigns);
      setTab2Data(inactiveDesigns);
     
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  // ...
  const reloadData = async () => {
    setLoading(true);
    await loadData();
    setLoading(false);
  };
  const resetFormFields = () => {
    setDesignId("");
    setDesignName("");
    setDesignImage("");
    setSelectedFile(null);
  };
  
  
  useEffect(() => {
    loadData(); // Initial data load
    setTimeout(() => {      
      setLoading(false); // Set loading to false after delay
    }, 2000);
   
  }, []);
  const handleSaveProgressColor = uploadProgress === 100 ? 'green' : 'default';
  const handleUpdateProgressColor = uploadProgress < 100 ? 'green' : 'default';


  return (
    <>
      {load ? (
        <div>
          <div className="loading-screen">
            <div className="lds-spinner-container">
              <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Box height={60} />
          <Typography variant="h6" component="h6" noWrap>
            Design Creation
          </Typography>

          <Container maxWidth="xxl">
            <Box mt={2}>
              <Card>
                <CardContent>
                  <div style={{ textAlign: "center", fontWeight: "bold" }}>
                    {" "}
                    {saveMessage && (
                      <p style={saveSuccess ? successStyle : errorStyle}>
                        {saveMessage}
                      </p>
                    )}
                    {updateMessage && (
                      <p style={updateSuccess ? successStyle : errorStyle}>
                        {updateMessage}
                      </p>
                    )}
                  </div>
                  <Box>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={6} md={6}></Grid>
                      <Grid item xs={12} sm={6} md={6} textAlign="right">
                        <Button
                          variant="contained"
                          startIcon={<AddAPhotoIcon />}
                          sx={{ backgroundColor: "green" }}
                          onClick={handleClickOpen}
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>

                  <TabContext value={value}>
                    <TabList
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      sx={{
                        display: 'flex',
                        '& .MuiTabScrollButton-root': {
                          display: 'none', // Hide the tab scroll buttons
                        },}}
                    >
                      <Tab label="Active" value="1" sx={{ flex: 1 }} />

                      <Tab label="Inactive" value="2" sx={{ flex: 1.2 }} />
                    </TabList>
                    <TabPanel value="1" style={{ height: '100%', overflow: 'hidden' }}>
                      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ backgroundColor: "grey.200" }}>
                                  S.No
                                </TableCell>
                                <TableCell sx={{ backgroundColor: "grey.200" }}>
                                  <Box display="flex" alignItems="center">
                                    Design Code
                                    {/* <IconButton size="small">
                                  <FilterAltIcon />
                                </IconButton> */}
                                  </Box>
                                </TableCell>
                                <TableCell sx={{ backgroundColor: "grey.200" }}>
                                  <Box display="flex" alignItems="center">
                                    Design Name
                                    {/* <IconButton size="small">
                                  <FilterAltIcon />
                                </IconButton> */}
                                  </Box>
                                </TableCell>
                                <TableCell sx={{ backgroundColor: "grey.200" }}>
                                  Design Imgage
                                </TableCell>
                                <TableCell sx={{ backgroundColor: "grey.200" }}>
                                  Actions
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {designData.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>{row.DesignID}</TableCell>
                                  <TableCell>{row.DesignName}</TableCell>
                                  <TableCell>
        <img
          src={row.DesignURL}
          alt="Design"
          style={{ width: '50px', cursor: 'pointer' }}
          onClick={() => setSelectedImageURL(row.DesignURL)}
        />
      </TableCell>{selectedImageURL && (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    }}
    onClick={() => setSelectedImageURL(null)} // Close the full-screen image
  >
    <img
      src={selectedImageURL}
      alt="Design"
      style={{ maxWidth: '90vw', maxHeight: '90vh', cursor: 'pointer' }}
    />
    <button
      onClick={() => setSelectedImageURL(null)}
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: 'white',
        color: 'black',
        border: 'none',
        cursor: 'pointer',
      }}
    >
      Close
    </button>
  </div>
)}


                                  <TableCell>
                                    <IconButton onClick={() => handleEdit(row)}>
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => handleDeactivate(row.SNo)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </TabPanel>

                    <TabPanel value="2">
                      <TableContainer
                        component={Paper}
                        sx={{ mt: 2, maxHeight: "600px" }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ backgroundColor: "grey.200" }}>
                                S.No
                              </TableCell>
                              <TableCell sx={{ backgroundColor: "grey.200" }}>
                                <Box display="flex" alignItems="center">
                                  Design ID
                                  {/* <IconButton size="small">
                                <FilterAltIcon />
                              </IconButton> */}
                                </Box>
                              </TableCell>
                              <TableCell sx={{ backgroundColor: "grey.200" }}>
                                <Box display="flex" alignItems="center">
                                  Design Name
                                  {/* <IconButton size="small">
                                <FilterAltIcon />
                              </IconButton> */}
                                </Box>
                              </TableCell>
                              <TableCell sx={{ backgroundColor: "grey.200" }}>
                                <Box display="flex" alignItems="center">
                                  Design Image
                                  {/* <IconButton size="small">                                
                              </IconButton> */}
                                </Box>
                              </TableCell>
                              <TableCell sx={{ backgroundColor: "grey.200" }}>
                                Inactive
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tab2Data &&
                              tab2Data.length > 0 &&
                              tab2Data.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>{row.DesignID}</TableCell>
                                  <TableCell>{row.DesignName}</TableCell>
                                  <TableCell>
                                    <img
                                      src={row.DesignURL}
                                      alt="Design"
                                      style={{ width: "100px" }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      onClick={() => handleActivate(row.SNo)}
                                    >
                                      <RestoreIcon />{" "}
                                    </IconButton>
                                    {/* <IconButton
          onClick={() => handleDelete(row.DesignID)}  
        >
          <DeleteIcon />
        </IconButton> */}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </TabContext>
                </CardContent>
              </Card>
            </Box>
          </Container>
        </>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullScreen
      >
   <DialogTitle
  id="form-dialog-title"
  style={{
    backgroundColor: "#4d84e2",
    color: "white",
    display: "flex",         // Use flex container
    alignItems: "center",    // Vertically center content
    justifyContent: "space-between", // Add this to push the close button to the right
  
  }}
>
  <div>Design Form</div>
  <IconButton
    onClick={handleClose}
    color="inherit"
    aria-label="Close"
    style={{marginTop:'-20px'}} // Set the initial color to white
    onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'black'} // Change color to black on hover
    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#4d84e2'} // Change color back to white when not hovering
  >
    <CloseIcon />
  </IconButton>
</DialogTitle>




        <DialogContent style={{ marginTop: "3%" }}>
          <DialogContentText>
            {/* Add any description or text here */}
          </DialogContentText>
          <TextField
            margin="dense"
            id="designId"
            label="Design ID"
            type="text"
            fullWidth
            value={designId}
            onChange={handleDesignIdChange}
            disabled={!!editedDesign}
          />
          <TextField
            margin="dense"
            id="designName"
            label="Design Name"
            type="text"
            fullWidth
            value={designName}
            onChange={handleDesignNameChange}
          />
          <div>
            <TextField
              margin="dense"
              id="designImage"
              type="file"
              fullWidth
              accept="image/*"
              onChange={handleDesignImageChange}
            />
            <img
              src={designImage} // Display the design image
              alt="Design"
              style={{ width: "30%" }}
            />
            {/* <input
    type="file"
    accept="image/*"
    onChange={handleDesignImageChange}
  /> */}
            {selectedFile && (
              <div>
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress}
                  sx={{ backgroundColor: handleSaveProgressColor }}
                />
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress}
                  sx={{ backgroundColor: handleUpdateProgressColor }}
                />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
  <Button onClick={handleClose} sx={{ backgroundColor: "red", color: "black" }}>
    Cancel
  </Button>
  <Button
    onClick={editedDesign ? handleUpdate : handleSave}
    sx={{ backgroundColor: "green", color: "black" }} // Set the text color to black
  >
    {editedDesign ? "Update" : "Save"}
  </Button>
</DialogActions>

      </Dialog>
    </>
  );
};

export default Design;
