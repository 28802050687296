import React from 'react'
import Box from "@mui/material/Box";

export default function WorkersRegistration() {
  return (
    <>
      <Box height={100}/>
      <div>WorkersRegistration</div>
    </>
    
  )
}
